@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/fonts.scss";
@import "../../../assets/scss/mixins.scss";

.Card {
  align-items: flex-start;
  backdrop-filter: blur(25px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) -30.29%, rgba(255, 255, 255, 0) 144.92%);
  border-radius: 15px;
  border: 1px solid #b7bdc675;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 205px;
  max-width: 285px; 
  min-height: 205px;
  min-width: 285px; 
  padding: 10px 25px;

  .blur-card {
    background: $blue;
    filter: blur(40px);
    height: 55px;
    left: 40px;
    position: absolute;
    top: 0px;
    width: 76px;
    z-index: 1;
  }

  img {
    height: 67.79px;
    width: 72.96px;
    z-index: 2;
  }

  p {
    color: $white;
    font-family: Montserrat, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
    z-index: 2;

    &:nth-child(3) {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-top: 16px;
    }
  }
}
