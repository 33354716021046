@mixin font-face($font-family, $font-suffix, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url($font-suffix) format("truetype"),
      url($font-suffix) format("opentype");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@include font-face(Montserrat,
  "../../assets/fonts/Montserrat-Bold.ttf",
  700,
  normal);

@include font-face(Montserrat,
  "../../assets/fonts/Montserrat-Regular.ttf",
  400,
  normal);

@include font-face(Rubik,
  "../../assets/fonts/Rubik-Regular.ttf",
  400,
  normal);

@include font-face(Rubik,
  "../../assets/fonts/Rubik-Medium.ttf",
  500,
  normal);
