@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Input {
  position: relative;
  width: 100%;
  

  label {
    color: #646693;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 3px;
    margin-bottom: 20px;

    .tooltip {
      display: flex;
      position: relative;

      svg {
        fill: white;
        margin: 0 10px;
        width: 15px;
        z-index: 1;
      }

      & > div {
        background-color: grey;
        color: white;
        left: -75px;
        max-width: 85vw;
        opacity: 0;
        padding: 10px 20px;
        position: absolute;
        top: 30px;
        transition: 0.4s;
        width: max-content;
        z-index: 2;

        @include breakpoint(tablet) {
          left: 30px;
          max-width: 420px;
          top: 0;
        }

        p {
          color: white;
          font-family: Rubki, sans-serif;
          font-size: 10px;
          margin: 0;
        }

        &.visible {
          opacity: 1;
        }
      }
    }
  }

  .input-container {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;

    background: #0B0B1E;
    border-radius: 4px;
    border: 1px solid #646693;
    box-sizing: border-box;
    

    input {
      flex-grow:2;

      background-color: transparent;
      background: #0B0B1E;
      border-radius: 4px;
      // border: 1px solid #646693;
      border: none;
      box-sizing: border-box;
      color: white;
      font-family: Rubik, sans-serif;
      font-size: 16px;
      font-weight: 500;
      height: 65px;
      outline: none;
      padding: 15px 55px 15px 15px;
      transition: 0.2s;
      width: 100%;

      &::placeholder {
        color: #646693;
        font-family: Rubik, sans-serif;
        font-size: 16px;
        font-weight: 500;
      }

      &.show-error {
        border-color: $error;

        &::placeholder {
          color: $error;
        }
      }

      &:disabled {
        border-color: transparent;
      }
    }

    button {
      align-items: center;
      background: #0B0B1E;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      height: 65px;
      margin: 0;
      opacity: 0.6;
      width: 50px;
    }
  }
  & > .feedback-message {
    color: white;
    font-size: 0.7rem;
    margin: 5px 5px 0 0;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }
}
