@import "../../../assets/scss/variables";

.Select {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    color: #646693;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 3px;
    margin-bottom: 20px;
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    background: #0B0B1E;
    background: url("../../../assets/images/angle-down.svg") 97.5% 50% no-repeat;
    border-radius: 4px;
    border: 1px solid #646693;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    padding: 0 30px;
    padding: 15px 55px 15px 15px;
    transition: 0.2s;
    width: 100%;

    &.show-error {
      border-color: $error;
      color: $error;
    }
  }

  option {
    background-color: $background_primary_dark_color;
    border: none;
    color: white;
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    outline: none;

    &:disabled {
      color: grey;
    }
  }
}
