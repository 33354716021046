@import "../../assets/scss/variables.scss";
@import "../../assets/scss/fonts.scss";
@import "../../assets/scss/mixins.scss";

.Plans {
  background-color: $background_project;
  display: flex;
  flex-direction: column;
  min-height: 413px;
  padding-bottom: 140px;
  width: 100%;

  .titles-plans {
    align-items: center;
    display: flex;
    flex-direction: column;

    p {
      color: $white;
      font-family: Montserrat, sans-serif;
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -1px;
      line-height: 73px;
      text-align: center;

      &:nth-child(2) {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.333333px;
        line-height: 24px;
        margin-top: 24px;
        max-width: 1121px;
        text-align: center;
      }
    }
  }

  .list-cards {
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    display: flex;
    list-style-type: none;
    margin-top: 50px;
    overflow-y: hidden;
    padding-top: 50px;
    position: relative;

    @include breakpoint(desktop-small) {
      justify-content: center;
    }
  }

  .has-scrollbar {
    display: flex;
    gap: 10px;
    margin-top: -30px;
    overflow-x: auto;
    padding-block-end: 50px;
    padding-top: 88px;
    scroll-snap-type: inline mandatory;

    @include breakpoint(desktop-small) {
      padding-block-end: 0px;
      gap: 55px;
    }
  }
  
  .has-scrollbar::-webkit-scrollbar { height: 12px; }
  
  .has-scrollbar::-webkit-scrollbar-button { width: calc(25% - 50px); }
  
  .has-scrollbar::-webkit-scroll  bar-track {
    outline: 2px solid #44B6F6;
    border-radius: 50px;
  }
  
  .has-scrollbar::-webkit-scrollbar-thumb {
    border: 3px solid $white;
    border-radius: 50px;
  }
}