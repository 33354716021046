@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

.SectionContact {
  align-items: center;
  background-color: $background_project;
  display: none;
  height: 800px;
  justify-content: center;
  padding: 200px 0;
  position: relative;

  img {
    z-index: 1;

    width: 100%;
  }

  .vector-section {
    height: 406.67px;
    left: 60%;
    position: absolute;
    top: 14%;
    width: 488px;

    filter: blur(116.19px);
    background: $blue;
  }

  @include breakpoint(desktop-small) {
    display: flex;
  }

  .form-contact {
    align-items: center;
    background: #181935;
    border-radius: 8px;
    bottom: 300px;    
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    left: 60%;
    padding: 0px 24px 40px;
    position: absolute;
    width: 521px;
    z-index: 1;

    .Input {
      margin: 10px 0;

      .feedback-message  {
        display: none;
      }
    }

    .Button {
      height: 65px;
      margin: 10px 0;
      width: 100%;
    }

    h3 {
      color: white;
      font-family: Montserrat, sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      margin-top: 30px;
    }

    h5 {
      font-family: Rubik, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 40px;
      text-align: center;
    }
  }
}