@import "../../assets/scss/variables.scss";
@import "../../assets/scss/fonts.scss";
@import "../../assets/scss/mixins.scss";

.Achievements {
  align-items: center;
  background-color: $background_project;
  display: flex;
  flex-direction: column;
  min-height: 413px;
  padding: 120px 0 100px 0;
  width: 100%;

  .vector-achie {
    background: linear-gradient(135deg, #46DEFF 1.18%, #44B6F6 75.21%);
    filter: blur(134.857px);
    left: -312px;
    position: absolute;
    top: 727px;
    height: 127px;
    width: 440.4px;

    @include breakpoint(mobile-large) {
      width: 440.4px;
      height: 367px;
    }
  }

  .titles-achievements {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    h2 {
      color: $white;
      font-family: Rubik, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 27px;

      &:nth-child(2) {
        font-family: Montserrat, sans-serif;
        font-size: 36px;
        font-weight: 700;
        margin-top: 18px;
      }
    }
  }

  .scores-achievements {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 87px;
    width: 70%;
    height: 100%;

    @include breakpoint(desktop-small) {
      display: flex;
      width: 60vw;
      flex-direction: row;
    }

    .score {
      align-items: center;
      display: flex;
      flex-direction: column;

      p {
        background-clip: text;
        background: $blue;
        font-family: Montserrat, sans-serif;
        font-size: 65px;
        font-weight: 700;
        line-height: 54px;
        margin: 0;
        text-align: center;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &:nth-child(2) {
          color: $grey;
          background: white;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: Rubik, sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          margin-top: 21px;
        }
      }
    }
  }
}