@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/fonts.scss";
@import "../../../assets/scss/mixins.scss";

.PlansCard {
  align-items: center;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 554px;
  justify-content: space-between;
  width: 358.54px;

  .infos {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 50px 30px 0;

    h3 {
      color: black;
      font-family: Montserrat, sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 49px;
      text-align: center;
    }

    p {
      color: #000000;
      font-family: Montserrat, sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-top: 13px;

      &:nth-child(3) {
        font-family: Rubik, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        margin: 24px 0 30px 0;
      }
    }

    .check-text {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      img {
        margin-top: 15px;
      }

      p {
        margin-left: 10px;
      }
    }
  }

  .Button {
    background: rgba(198, 199, 235, 0.3);
    border-radius: 26px;
    color: #44B6F6;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    height: 52px;
    letter-spacing: -0.233333px;
    line-height: 17px;
    margin-bottom: 40px;
    width: 274.24px;
  }
}

.recommended {
  align-items: center;
  background: linear-gradient(135deg, #466FFF 1.18%, #44B6F6 101.34%);
  border-radius: 26px;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  left: 28%;
  top: -20px;
  width: 152.59px;

  p {
   color: white;
    font-size: 10px;
    font-family: Montserrat, sans-serif;
  }
}
