$text_primary_light_color: #FFFFFF;
$base_font_size: 20px;

$background_project: #0B0B1E;

$blue: linear-gradient(135deg, #46DEFF 1.18%, #44B6F6 75.21%);
$blue_dark: linear-gradient(135deg, #466FFF 1.18%, #44B6F6 101.34%);
$white: #FFFFFF;
$grey: #B7BDC6;

$error: #e50000;
$background_primary_dark_color: #0B0B1E;

$breakpoints: (
  "mobile-small": 400px,
  "mobile-large": 576px,
  "tablet": 768px,
  "tablet-portrait": 992px,
  "desktop-small": 1200px,
  "desktop-medium": 1400px,
  "desktop-large": 1600px,
  "desktop-extra-large": 1800px
);
