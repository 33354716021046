@import "../../assets/scss/variables.scss";
@import "../../assets/scss/fonts.scss";
@import "../../assets/scss/mixins.scss";

.HomeStarter {
  align-items: flex-start;
  background-color: $background_project;
  display: flex;
  height: 713px;
  justify-content: space-around;
  padding-top: 64px;
  width: 100%;

  .starter {
    display: flex;

    .texts-starter {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 116px 0 0 10px;

      @include breakpoint(mobile-large) {
        align-items: flex-start;
        margin: 116px 0 0 67px;
        width: 595px;
      }
  
      h1 {
        font-family: Montserrat, sans-serif;
        font-size: 39px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px;
  
        &:nth-child(2) {
          background-clip: text;
          background: $blue;
          font-size: 39px;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        @include breakpoint(mobile-large) {
          font-size: 60px;

          &:nth-child(2) {
            font-size: 60px;
          }
        }
      }
  
      p {
        font-family: Rubik;
        font-size: 25px;
        font-weight: 400;
        margin-top: 24px;
      }
  
      .Button {
        height: 59px;
        margin-top: 32px;
        width: 343px;

        @include breakpoint(mobile-large) {
          width: 548px;
        }
      }
    }
  
    .image-starter {
      img {
        display: none;
        padding-top: 50px;
  
        @include breakpoint(desktop-small) {
          display: flex;
          width: 45vw;
        }
  
        @include breakpoint(desktop-medium) {
          display: flex;
          width: 50vw;
        }
      }
    }
  }
}
