@import "../../assets/scss/variables.scss";
@import "../../assets/scss/fonts.scss";
@import "../../assets/scss/mixins.scss";

.Platform {
  background-color: $background_project;
  display: flex;
  flex-direction: column;
  min-height: 413px;
  padding: 120px 0;
  width: 100%;

  @include breakpoint(desktop-small) {
    flex-direction: row;
  }

  .text-platform {
    padding: 0 0 0 40px;

    p {
      background: $blue;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Montserrat, sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      width: 95%;

      &:nth-child(2) {
        background: $white;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Rubik, sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        margin-top: 16px;
      }

      @include breakpoint(desktop-small) {
        width: 430px;

        &:nth-child(2) {
          width: 440px;
        }
      }
    }
  }

  .carrousel-platform {
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    display: flex;
    list-style-type: none;
    margin-top: 50px;
    overflow-y: hidden;
    position: relative;
    height: 230px;

    .Card {
      margin-left: 10px;
    }
  }

  .has-scrollbar {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    margin-top: -30px;
    padding-block-end: 20px;
    padding-top: 88px;
    scroll-snap-type: inline mandatory;

    @include breakpoint(desktop-small) {
      margin-top: -95px;
    }
  }
  
  .has-scrollbar::-webkit-scrollbar { height: 12px; }
  
  .has-scrollbar::-webkit-scrollbar-button { width: calc(25% - 50px); }
  
  .has-scrollbar::-webkit-scrollbar-track {
    outline: 2px solid #44B6F6;
    border-radius: 50px;
  }
  
  .has-scrollbar::-webkit-scrollbar-thumb {
    border: 3px solid $white;
    border-radius: 50px;
  }
}
