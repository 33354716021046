@import "../assets/scss/mixins.scss";
@import "../assets/scss/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

.App {
  min-height: 100vh;
  position: relative;

  * {
    color: $text_primary_light_color;
    font-family: 'Montserrat', sans-serif;
    font-size: $base_font_size;
    font-weight: 400;
    line-height: 33px;
    margin: 0;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 115.4%;
  }

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 49px;
  }

  strong {
    font-weight: 900;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 35px;
  }

}