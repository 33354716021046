@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

.Navbar {
  align-items: center;
  background-color: $background_project;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0 140px;
  position: fixed;
  width: 100%;
  z-index: 99;

  .text-navbar {
    align-items: center;
    background-color: #8693a77e;
    display: flex;
    font-size: 15px;
    height: 20px;
    position: absolute;
    right: 21%;
    top: 75%;
  }

  img {
    height: 33px;
  }

  .selects {
    cursor: not-allowed;
    display: none;
    margin-right: 250px;

    @include breakpoint(mobile-large) {
      display: flex;
    }
  }

  .select-language {
    align-items: center;
    display: flex;
    height: 28px;
    width: 168.43px;

    a {
      align-items: center;
      display: flex;

      span {
        font-size: 14px;
        font-weight: 700;
      }

      img {
        margin-left: 3px;
        height: 19.37px;
        width: 19.37px;
      }
    }

    p {
      border: 1px solid #646693;
      flex-grow: 0;
      flex: none;
      height: 0px;
      transform: rotate(90deg);
      width: 28px;
    }

    img {
      height: 26.06px;
      width: 26.06px;
    }
  }
}
