@import "../../assets/scss/variables.scss";
@import "../../assets/scss/fonts.scss";
@import "../../assets/scss/mixins.scss";

.Footer {
  background-color: $background_project;
  height: 100%;

  @include breakpoint(mobile-large) {
    height: 513px;
  }

  .header-footer {
    display: flex;
    flex-direction: column;
    padding-top: 103px;

    @include breakpoint(mobile-large) {
      flex-direction: row;
      justify-content: space-between;
    }


    .image-footer {
      width: 90%;

      img {
        margin: -30px 0 40px 0;

        @include breakpoint(mobile-large) {
          margin-left: 82px;
        }
      }
    }


    .buttons-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include breakpoint(mobile-large) {
        flex-direction: row;
        justify-content: flex-start;
        width: 70%;
      }

      div {
        align-items: center;
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin: 20px 0;

        ul {
          color: white;
          cursor: pointer;
          font-family: Montserrat, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 12px;
          margin-bottom: 30px;
          padding: 0;
        }

        li {
          color: #9597B1;
          cursor: pointer;
          font-family: 'Rubik';
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 0.1em;
          line-height: 24px;
          margin-bottom: 6px;
        }

        @include breakpoint(mobile-large) {
          align-items: flex-start;
          margin: 0; 
          width: 250px;
        }
      }

      .desk-desc {
        margin-top: 40px;
      }
    }
  }

  .bottom-footer {
    bottom: -20%;
    display: flex;
    justify-content: space-between;
    position: relative;

    div {
      display: flex;

      p {
        color: #9597B1;
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      &:nth-child(2) {

        p {
          color: #9597B1;
          font-family: 'Rubik';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-left: 20px;
          text-align: right;
          text-decoration-line: underline;
        }
      }
    }

    @include breakpoint(mobile-large) {
      padding: 0 50px;
    }
  }
}
