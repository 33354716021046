@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/fonts.scss";

.Button {
  background: $blue_dark;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  text-align: center;

  &.larger {
    height: 59px;
    width: 548px;
  }
}
